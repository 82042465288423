import React from "react"
import { graphql } from "gatsby"

import BlogTemplate from "./blog-template"

export default function BlogCategory({ data, pageContext }) {
  const page = data.allWpCategory.nodes[0]
  const posts = data.allWpPost

  return (
    <BlogTemplate
      {...pageContext}
      page={page}
      posts={posts.nodes}
      totalCount={posts.totalCount}
      backToBlog={true}
      categoryName={`${page.name}`}
    />
  )
}

export const pageQuery = graphql`query ($skip: Int = 0, $pageSize: Int = 9, $slug: String!) {
  allWpCategory(filter: {slug: {eq: $slug}}, limit: 1) {
    nodes {
      uri
      slug
      name
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
    }
  }
  allWpPost(
    filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
    sort: {fields: [date], order: DESC}
    limit: $pageSize
    skip: $skip
  ) {
    totalCount
    nodes {
      title
      excerpt
      slug
      date
      uri
      dateFormatted: date(formatString: "D/MM/YYYY")
      timeToRead
      author {
        node {
          firstName
          lastName
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 450
                height: 388
                transformOptions: {cropFocus: ENTROPY}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`
